import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { post, put } from '../../services/api';
import SearchBar from '../SearchBar';
import './Stocks.css';

function Stocks() {
    const [stockItems, setStockItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCanteenId, setSelectedCanteenId] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const [manualStockModal, setManualStockModal] = useState(null);
    const [manualQuantity, setManualQuantity] = useState('');
    const { adminData } = useAuth();

    const canteenList = adminData
        ? adminData.canteenId.map((id, index) => ({
            canteenId: id,
            canteenName: adminData.canteenName[index]
        }))
        : [];

    const handleCanteenChange = (event) => {
        setSelectedCanteenId(event.target.value);
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    useEffect(() => {
        const preferredCanteenId = localStorage.getItem('preferredCanteenId');
        if (preferredCanteenId) {
            setSelectedCanteenId(preferredCanteenId); // Set the dropdown to the stored preferred value
        }
    }, []);

    useEffect(() => {
        const fetchStockItems = async () => {
            try {
                let requestBody = {};
                if (selectedCanteenId === 'All') {
                    requestBody = { canteenIds: adminData.canteenId.map(id => parseInt(id)) };
                } else {
                    requestBody = { canteenIds: [parseInt(selectedCanteenId)] };
                }
                const response = await post('v1/stocks/admin/stockItems', requestBody);
                if (response) {
                    setStockItems(response);
                    setIsLoading(false);
                } else {
                    console.error('Response data is undefined:', response);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching stock items:', error);
                setIsLoading(false);
            }
        };

        fetchStockItems();
    }, [selectedCanteenId, adminData.canteenId]);

    const handleStockChange = async (menuItemId, stockChange) => {
        try {
            const response = await put(`v1/stocks/admin/updateStock/${menuItemId}`, {
                stockChange,
                modifiedBy: adminData.adminId
            });

            if (response) {
                // Update local state to reflect the stock change
                setStockItems(prevItems =>
                    prevItems.map(item =>
                        item.menuItemId === menuItemId
                            ? { ...item, stockQuantity: item.stockQuantity + stockChange }
                            : item
                    )
                );
            }
        } catch (error) {
            console.error('Error updating stock:', error);
            alert('Failed to update stock. Please try again.');
        }
    };

    const openManualStockModal = (item) => {
        setManualStockModal(item);
        setManualQuantity('');
    };

    const handleManualStockSubmit = async () => {
        if (!manualStockModal) return;

        // Validate input
        const quantity = parseInt(manualQuantity);
        if (isNaN(quantity)) {
            alert('Please enter a valid number');
            return;
        }

        // Calculate stock change
        const stockChange = quantity - manualStockModal.stockQuantity;

        try {
            const response = await put(`v1/stocks/admin/updateStock/${manualStockModal.menuItemId}`, {
                stockChange,
                modifiedBy: adminData.adminId
            });

            if (response) {
                // Update local state to reflect the stock change
                setStockItems(prevItems =>
                    prevItems.map(item =>
                        item.menuItemId === manualStockModal.menuItemId
                            ? { ...item, stockQuantity: quantity }
                            : item
                    )
                );

                // Close the modal
                setManualStockModal(null);
            }
        } catch (error) {
            console.error('Error updating stock:', error);
            alert('Failed to update stock. Please try again.');
        }
    };

    const filteredStockItems = stockItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <div>
            <h2>Stock Management</h2>
            <div className="search-bar">
                <SearchBar onSearch={handleSearch} />
            </div>
            {isLoading ? (
                <p>Loading stock items...</p>
            ) : (
                <div>
                    <div className='top-area'>
                        <select
                            className='canteen-dropdown'
                            onChange={handleCanteenChange}
                            value={selectedCanteenId}
                        >
                            <option value="All">All Canteens</option>
                            {canteenList.map((canteen) => (
                                <option key={canteen.canteenId} value={canteen.canteenId}>
                                    {canteen.canteenName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="stocks-container">
                        {filteredStockItems.map((item) => (
                            <div key={item.menuItemId} className="stock-card">
                                <img
                                    src={item.foodImage}
                                    alt={item.foodName}
                                />
                                <h3>{item.foodName}</h3>
                                {selectedCanteenId == 'All' &&
                                    <p>{item.canteenName}</p>
                                }
                                <p className="stock-food-category">{item.foodCategory}</p>
                                <div className="stock-controls">
                                    <button
                                        className="quantity-btn"
                                        onClick={() => handleStockChange(item.menuItemId, -10)}
                                        disabled={item.stockQuantity < 10}
                                    >
                                        -10
                                    </button>
                                    <button
                                        className="quantity-btn"
                                        onClick={() => handleStockChange(item.menuItemId, -5)}
                                        disabled={item.stockQuantity < 5}
                                    >
                                        -5
                                    </button>
                                    <span
                                        className="stock-quantity"
                                        onClick={() => openManualStockModal(item)}
                                    >
                                        {item.stockQuantity}
                                    </span>
                                    <button
                                        className="quantity-btn"
                                        onClick={() => handleStockChange(item.menuItemId, 5)}
                                    >
                                        +5
                                    </button>
                                    <button
                                        className="quantity-btn"
                                        onClick={() => handleStockChange(item.menuItemId, 10)}
                                    >
                                        +10
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Manual Stock Input Modal */}
                    {manualStockModal && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <h3>Update Stock Quantity</h3>
                                <p>Updating stock for: {manualStockModal.foodName}</p>
                                <input
                                    type="number"
                                    value={manualQuantity}
                                    onChange={(e) => setManualQuantity(e.target.value)}
                                    placeholder="Enter new quantity"
                                    min="0"
                                />
                                <div className="modal-actions">
                                    <button onClick={handleManualStockSubmit}>Update</button>
                                    <button onClick={() => setManualStockModal(null)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Stocks;