import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { post } from '../../services/api';
import './Admins.css';

function Admins() {
  const [admins, setAdmins] = useState([]);
  const [selectedCanteenId, setSelectedCanteenId] = useState('All');
  const { adminData } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const canteenList = adminData
    ? adminData.canteenId.map((id, index) => ({
      canteenId: id,
      canteenName: adminData.canteenName[index]
    }))
    : [];

  const handleCanteenChange = (event) => {
    setSelectedCanteenId(event.target.value);
  };

  const fetchAdmins = async () => {
    setIsLoading(true);
    try {
      let requestBody = {};
      if (selectedCanteenId === 'All') {
        requestBody = { canteenIds: adminData.canteenId.map(id => parseInt(id)) };
      } else {
        requestBody = { canteenIds: [parseInt(selectedCanteenId)] };
      }
      const response = await post('v1/canteens/admins', requestBody);
      setAdmins(response);
    } catch (error) {
      console.error('Error fetching admins:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const preferredCanteenId = localStorage.getItem('preferredCanteenId');
    if (preferredCanteenId) {
      setSelectedCanteenId(preferredCanteenId); // Set the dropdown to the stored preferred value
    }
  }, []);

  useEffect(() => {
    if (selectedCanteenId) {
      fetchAdmins();
    }
  }, [selectedCanteenId]);

  const handleAdminClick = (admin) => {
    setSelectedAdmin({ ...admin }); // Copy admin object to avoid mutating original state
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAddUser = () => {
    setSelectedAdmin(null);
    setIsEditMode(false);
    setShowModal(true);
  };

  const handlePermissionChange = (event) => {
    const { name, value } = event.target;
    setSelectedAdmin((prevAdmin) => ({
      ...prevAdmin,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const requestBody = {
        admin: {
          name: selectedAdmin.name,
          email: selectedAdmin.email,
          role: selectedAdmin.role
        }
      };
      if (isEditMode) {
        await post('v1/canteens/updateKioskDetails', requestBody);
      } else {
        await post('v1/canteens/registerKioskAdmin', requestBody);
      }
      fetchAdmins();
      setShowModal(false);
    } catch (error) {
      console.error('Error saving admin:', error);
    }
  };

  const handleBlockUser = async (adminId, isBlocked) => {
    try {
      if (isBlocked) {
        await post(`v1/blockuser/unblockUser/${adminId}`);
      } else {
        await post(`v1/blockuser/blockUser/${adminId}`);
      }
      fetchAdmins();
    } catch (error) {
      console.error('Error blocking/unblocking admin:', error);
    }
  };

  return (
    <main className="admins-container">
      <h2>Manage Admins</h2>
      <div className="admin-top-area">
        <select className='admin-canteen-dropdown' value={selectedCanteenId} onChange={handleCanteenChange}>
          <option value="All">All Canteens</option>
          {canteenList.map((canteen) => (
            <option key={canteen.canteenId} value={canteen.canteenId}>
              {canteen.canteenName}
            </option>
          ))}
        </select>
        <button className="add-button" onClick={handleAddUser}>Add User</button>
      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="admins-cards">
          {admins.map((admin) => (
            <div key={admin.adminId} className="admin-card">
              <div className="admin-card-details">
                <h4>{admin.name}</h4>
                <p>Email: {admin.email}</p>
                <p>Role: {admin.role}</p>
              </div>
              <div className="admin-card-actions">
                <button onClick={() => handleAdminClick(admin)}>Edit</button>
                <button onClick={() => handleBlockUser(admin.adminId, admin.isBlocked)}>
                  {admin.isBlocked ? 'Unblock User' : 'Block User'}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {showModal && (
        <div className="admin-popup-overlay">
          <div className="admin-popup">
            <h3>{isEditMode ? 'Edit Admin' : 'Add Admin'}</h3>
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={selectedAdmin ? selectedAdmin.name : ''}
                onChange={handlePermissionChange}
                required
              />
            </label>
            <label>
              Email:
              <input
                type="text"
                name="email"
                value={selectedAdmin ? selectedAdmin.email : ''}
                onChange={handlePermissionChange}
                required
              />
            </label>
            <div className="modal-actions">
              <button onClick={handleSave}>Save</button>
              <button onClick={() => setShowModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default Admins;
