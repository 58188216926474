import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { post, put } from '../../services/api';
import SearchBar from '../SearchBar';
import '../Stocks/Stocks.css';  // Reusing the same CSS

function Availability() {
    const [menuItems, setMenuItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCanteenId, setSelectedCanteenId] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const { adminData } = useAuth();

    const canteenList = adminData
        ? adminData.canteenId.map((id, index) => ({
            canteenId: id,
            canteenName: adminData.canteenName[index]
        }))
        : [];

    const handleCanteenChange = (event) => {
        setSelectedCanteenId(event.target.value);
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    useEffect(() => {
        const preferredCanteenId = localStorage.getItem('preferredCanteenId');
        if (preferredCanteenId) {
            setSelectedCanteenId(preferredCanteenId);
        }
    }, []);

    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                let requestBody = {};
                if (selectedCanteenId === 'All') {
                    requestBody = { canteenIds: adminData.canteenId.map(id => parseInt(id)) };
                } else {
                    requestBody = { canteenIds: [parseInt(selectedCanteenId)] };
                }
                const response = await post('v1/availability/admin/menuItems', requestBody);
                if (response) {
                    setMenuItems(response);
                    setIsLoading(false);
                } else {
                    console.error('Response data is undefined:', response);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching menu items:', error);
                setIsLoading(false);
            }
        };

        fetchMenuItems();
    }, [selectedCanteenId, adminData.canteenId]);

    const handleAvailabilityToggle = async (menuItemId, currentAvailability) => {
        try {
            const response = await put(`v1/availability/admin/updateAvailability/${menuItemId}`, {
                availability: !currentAvailability,
                modifiedBy: adminData.adminId
            });

            if (response) {
                // Update local state to reflect the availability change
                setMenuItems(prevItems =>
                    prevItems.map(item =>
                        item.menuItemId === menuItemId
                            ? { ...item, availability: !item.availability }
                            : item
                    )
                );
            }
        } catch (error) {
            console.error('Error updating availability:', error);
            alert('Failed to update availability. Please try again.');
        }
    };

    const filteredMenuItems = menuItems.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <div>
            <h2>Availability Management</h2>
            <div className="search-bar">
                <SearchBar onSearch={handleSearch} />
            </div>
            {isLoading ? (
                <p>Loading menu items...</p>
            ) : (
                <div>
                    <div className='top-area'>
                        <select
                            className='canteen-dropdown'
                            onChange={handleCanteenChange}
                            value={selectedCanteenId}
                        >
                            <option value="All">All Canteens</option>
                            {canteenList.map((canteen) => (
                                <option key={canteen.canteenId} value={canteen.canteenId}>
                                    {canteen.canteenName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="stocks-container">
                        {filteredMenuItems.map((item) => (
                            <div key={item.menuItemId} className="stock-card">
                                {selectedCanteenId === 'All' &&
                                    <p>{item.canteenName}</p>
                                }
                                <img
                                    src={item.foodImage}
                                    alt={item.foodName}
                                />
                                <h3>{item.foodName}</h3>
                                <p className="stock-food-category">{item.foodCategory}</p>
                                <div className="availability-controls">
                                    <label className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            checked={item.availability}
                                            onChange={() => handleAvailabilityToggle(item.menuItemId, item.availability)}
                                        />
                                        <span className="toggle-slider"></span>
                                    </label>
                                    <span className={`availability-status ${item.availability ? 'available' : 'unavailable'}`}>
                                        {item.availability ? 'Available' : 'Unavailable'}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Availability;